html {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body {
  background-position: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  user-select: none;
}

.innerApp {
  display: inline-block;
  padding: 51px;
  padding-top: 36px;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
  top: 91px;
}

div.bkg {
  position: absolute;
  top: 91px;
  left: 0px;
  background-image: linear-gradient(25deg, rgba(1,5,13,1) 0%, rgba(3,13,59,1) 43%, rgba(30,73,127,1) 100%);
  background-repeat: no-repeat;
  height: calc(100% - 91px);
  width: 100vw;
  overflow: hidden;
}

img.bkgImage {
  position: relative;
  top: 24px;
  width: 104%;
  max-width: 600px;
  opacity: .42;
}

@media screen and (max-height: 760px) {
  div.bkg {
    height: calc(100% - 67px);
    top: 67px;
  }

  .innerApp {
    padding-top: 13px;
    top: 67px;
  }
}

@media screen and (max-height: 590px) {
  div.innerApp {
    width: 90%;
  }
}