div.standbyHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

img.logo {

}



p.playerName {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 39px;
  font-weight: bold;
  margin-bottom: 10px;
}

p.gameSubtext {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 25px;
  width: 265px;
}

@media screen and (max-height: 760px) {
  img.logo {
    width: 250px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 315px) {
  img.logo {
    width: 200px;
  }
}