div.gameHolder {
  
}

p.playerName {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: bold;
}

p.score {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
}

div.optionsHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  top: 25px;
}

div.gameInfoHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

@media screen and (max-height: 760px) {
  p.playerName {
    font-size: 30px;
  }
  
  p.score {
    font-size: 30px;
  }

  div.optionsHolder {
    top: 10px;
  }
}

@media only screen and (max-width: 375px) {
  p.playerName {
    font-size: 25px;
  }

   p.score {
    font-size: 25px;
  }
}