div.disconnectOverlay {
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 51px;
}

div.disconnectText {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 154px;
}

p.standby {
  margin-bottom: -8px;
}

div.disconnectOverlay p {
  font-size: 20;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: white;
}