button.option {
  width: 100%;
  height: 128px;
  display: inline-block;
  margin-bottom: 9px;
  border: none;
  border-radius: 10px;
  padding: 0;
  font-size: 88px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: #8800CC;
  opacity: 1;
}

button.option:hover {
  cursor: pointer;
}

button.optionDisabled {
  opacity: .66 !important;
}

button.optionCorrect {
  background-color: #00BECC;
}

button.optionSelected {
  background-color: #61737B;
}

button.optionHidden {
  display: none;
}

@media screen and (max-height: 760px) {
  button.option {
    font-size: 80px;
    height: 118px;
  }
}

@media screen and (max-height: 670px) {
  button.option {
    font-size: 75px;
    height: 100px;
  }
}

@media screen and (max-height: 590px) {
  button.option {
    height: 85px;
    font-size: 65px;
  }
}

@media screen and (max-height: 525px) {
  button.option {
    height: 80px;
    font-size: 60px;
  }
}

@media screen and (max-height: 505px) {
  button.option {
    height: 70px;
    font-size: 50px;
    margin-bottom: 5px;
  }
}