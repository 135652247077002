div.landscapeOverlay {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
  box-sizing: border-box;
  position: static;
}

div.landscapeOverlay p {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: white;
  z-index: 9999;
}

@media (orientation: landscape) and (min-width: 475px) {
  div.landscapeOverlay {
    display: flex;
  }
}

@media (orientation: landscape) and (max-width: 474.99px) {
  div.landscapeOverlay {
    display: none;
  }
}

@media (orientation: portrait) {
  div.landscapeOverlay {
    display: none;
  }
}
