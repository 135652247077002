div.intakeHolder {
  display: inline-block;
  width: 100%;
  position: relative;
  bottom: 33px;
  text-align: center;
}

h1.intakeHeader {
  font-size: 25px;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  width: 260px;
  display: inline-block;
}


input.intakeInput {
  width: 100%;
  height: 73px;
  border-radius: 8px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid transparent;
  box-shadow: none !important;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  box-sizing: border-box;
  color: #003C71;
  margin-bottom: 20px;
  padding-left: 10px;
}

input.intakeInput::placeholder {
  color: #003C71;
}

button.joinButton, button.joinButton:focus {
  display: inline-block;
  width: 100%;
  height: 76px;
  background-color: #BB33FF;
  color: white;
  font-size: 35px;
  font-weight: bold;
  border-radius: 8px;
  position: relative;
  top: 16px;
  outline: none;
  border: 1px solid transparent;
}

button.joinButton:hover {
  cursor: pointer;
}

button.joinButton:disabled {
  opacity: 0.7;
}

@media only screen and (max-height: 760px) {
  h1.intakeHeader {
    margin-top: 37px;
  }
}

@media only screen and (max-width: 375px) {
  h1.intakeHeader {
    font-size: 20px;
    width: 200px;
  }
}