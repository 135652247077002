.header {
  width: 100%;
  height: 91px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: absolute;
  top: 0px;
  left: 0px;
}

.heaerImage {
  z-index: 9999;
}

@media screen and (max-height: 760px) {
  .header {
    height: 67px;
  }

  .headerImage {
    width: 110px;
    height: auto;
  }
}