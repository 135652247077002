div.endHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.gameInfoHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

p.playerName {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: bold;
}

p.score {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
}

img.logo {
  position: relative;
  top: 40px;
}

p.endText {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  position: relative;
  top: 50px;
}

@media screen and (max-height: 760px) {
  p.score {
    font-size: 30px;
  }

  p.playerName {
    font-size: 30px;
  }
  
  img.logo {
    width: 250px;
  }
}

@media only screen and (max-width: 375px) {
  p.playerName {
    font-size: 25px;
  }

   p.score {
    font-size: 25px;
  }
}